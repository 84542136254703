<template>
  <v-app>
    <v-app-bar app color="#080061" dark v-if="authenticated">
      <v-btn text class="header-logo"
        ><img src="./assets/logo.png" id="logo" alt="logo" /><span id="title"
          >SUNA</span
        ><span id="logo_title"></span
      ></v-btn>
      <div class="add" v-if="authenticated && isAdmin">
        <v-spacer>
          <router-link
            @isAdmin="setPermission"
            v-if="authenticated && isAdmin"
            to="/home"
            replace
            class="permissionRoute home"
            >Home</router-link
          >
          <router-link
            @isAdmin="setPermission"
            v-if="authenticated && isAdmin"
            to="/eventAnalyzer"
            replace
            class="permissionRoute eventAnalyzer"
            >Event Analyzer</router-link
          >
          <router-link
            @isAdmin="setPermission"
            v-if="authenticated && isAdmin"
            to="/spreadInfo"
            replace
            class="permissionRoute spreadInfo"
            >Spread Info</router-link
          >
          <router-link
            v-if="authenticated && isAdmin"
            to="/accountSimulation"
            replace
            class="permissionRoute accountSimulation"
            >Account Simulation</router-link
          >
          <router-link
            v-if="authenticated"
            to="/login"
            v-on:click.native="logout()"
            replace
            id="to_login"
            ><v-icon dark> mdi-logout </v-icon></router-link
          >
        </v-spacer>
        <p id="localtime"></p>
      </div>
    </v-app-bar>

    <v-main>
      <router-view @authenticated="setAuthenticated" />
    </v-main>
  </v-app>
</template>

<script>
import $ from "jquery";
import "material-design-icons-iconfont/dist/material-design-icons.css";
export default {
  name: "App",
  data() {
    return {
      authenticated: false,
      isAdmin: false,
    };
  },
  mounted() {
    this.authenticated = false;
    if (localStorage.getItem("user")) this.authenticated = true;
    this.isAdmin = localStorage.getItem("permission");
    setTimeout(() => {
      if (this.isAdmin == "true") {
        $(".permissionRoute").removeClass("hidden");
      } else {
        $(".permissionRoute").addClass("hidden");
      }
    }, 200);
  },
  methods: {
    setAuthenticated(status) {
      this.authenticated = status;
    },
    setPermission(status) {
      this.isAdmin = status;
    },

    logout() {
      localStorage.removeItem("user");
      localStorage.removeItem("permission");
      localStorage.removeItem("demoToken");
      localStorage.removeItem("prevPath");
      this.authenticated = false;
      if (localStorage.getItem("visitor") == "admin")
        this.$router.push({ path: "/login" });
      else this.$router.push({ path: "/guest/login" });
    },
  },
};
</script>
