<template>
  <v-col class="main" lg="3" sm="6" xs="12" md="4">
    <img class="background" src="../assets/background.png" alt="background" />
    <div id="notFound_container">
      <h1 id="notFound_lbl">Page not Found</h1>
      <p>404</p>
    </div>
  </v-col>
</template>

<script>
import $ from "jquery";
export default {
  name: "notFound",
  data: () => ({}),
  async mounted() {
    $("#title").html("SUNA");
    $("#logo_title").html("");
    $(".v-main").removeClass("guestPage");
    $(".v-main").removeClass("loginPage");
    $(".v-main").removeClass("adminPage");
    $(".v-main").removeClass("homePage");
    $(".v-main").removeClass("eventPage");
    $(".v-application--wrap").removeClass("spreadPage");
    $(".v-application--wrap").removeClass("tradingUnits");
    $("#localtime").removeClass("hidden");
    $("#localtime").addClass("hidden");
    $(".v-main").addClass("notfound");
  },
};
</script>
<style>
.notfound.v-main {
  width: auto;
  min-width: 300px !important;
  text-align: center;
}
#notFound_lbl {
  margin: 25px 0;
  color: red;
}
#notFound_container p {
  font-size: 100px;
  font-weight: 900;
  color: blue;
}
#notFound_container {
  padding: 20px;
  box-shadow: 1px 2px 15px 1px #0005;
  background: #fff5;
  border-radius: 15px;
  z-index: 1;
}
</style>
