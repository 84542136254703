<template>
  <v-row class="pt-1 mt-0">
    <v-col class="col eventContainer col-12" xl="3" lg="3" sm="8" md="4">
      <div class="row filterContainer">
        <v-col class="col-3">
          <v-combobox
            v-model="selectedCurrency"
            :items="currencyList"
            label="Currency"
            outlined
            dense
            @change="getEvents()"
            id="sel_currency"
          ></v-combobox>
        </v-col>
        <v-col class="col-3">
          <v-select
            v-model="relevance"
            :items="items_relevance"
            label="Relevance"
            outlined
            dense
            id="sel_relevance"
            @change="getEvents()"
          >
          </v-select>
        </v-col>
        <v-col class="col-6">
          <v-text-field
            v-model="eventSearch"
            label="Search"
            outlined
            dense
            clearable
            id="search_event"
            @keyup="searchEvent()"
            @change="searchEvent()"
          ></v-text-field>
        </v-col>
      </div>
      <v-card class="mx-auto" max-width="95%">
        <v-list shaped>
          <v-toolbar color="pink" dark>
            <v-toolbar-title id="eventListTitle"></v-toolbar-title>
          </v-toolbar>

          <v-list-item-group v-model="selectedEventIndex" color="primary">
            <v-list-item v-for="(event, i) in eventList" :key="i">
              <v-list-item-content>
                <v-list-item-title
                  v-text="event"
                  @click="selEvent(i)"
                ></v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-card>
    </v-col>

    <!-- ---------------------------------------------- -->

    <v-col class="col calendarContainer col-12" xl="3" lg="3" sm="4" md="3">
      <div id="sel_eventdate_range">
        <v-menu
          ref="menu"
          v-model="menu"
          :close-on-content-click="false"
          :return-value.sync="dates"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="dateRangeText"
              prepend-icon="mdi-calendar"
              v-bind="attrs"
              v-on="on"
              readonly
            ></v-text-field>
          </template>
          <v-date-picker v-model="sel_dates" range>
            <v-spacer></v-spacer>
            <!-- <v-btn text color="primary" @click="menu = false"> Cancel </v-btn>
            <v-btn text color="primary" @click="$refs.menu.save(dates)"> -->
            <v-btn text color="primary" @click="cancelDates()"> Cancel </v-btn>
            <v-btn text color="primary" @click="saveDates()">
              OK
            </v-btn>
          </v-date-picker>
        </v-menu>
      </div>
      <v-card class="mx-auto" max-width="100%">
        <v-toolbar color="green" dark>
          <v-toolbar-title>Event Date </v-toolbar-title>
        </v-toolbar>

        <v-list-item-group v-model="selectedEventTime" color="primary">
          <v-list-item v-for="(eventTime, i) in eventTimeList" :key="i">
            <v-list-item-content>
              <v-list-item-title v-text="eventTime"></v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-card>
    </v-col>

    <!-- ---------------------------------------------- -->

    <v-col class="col analyzerContainer col-12" xl="6" lg="6" sm="12" md="5">
      <div class="row filterContainer symbol_interval">
        <v-combobox
          v-model="selectedSymbol"
          :items="symbolList"
          label="Symbol"
          outlined
          dense
          @change="changedIntervals()"
          id="sel_symbol"
        ></v-combobox>
        <v-combobox
          v-model="selectedIntervals"
          :items="intervalList"
          label="Time Inteval"
          outlined
          dense
          multiple
          @change="changedIntervals()"
          id="sel_Intervals"
        ></v-combobox>
      </div>
      <v-data-table
        :headers="headers_tbl_analyzer"
        :items="items_tbl_analyzer"
        id="tbl_analyzer"
      ></v-data-table>
    </v-col>

    <!-- ---------------------------------------------- -->
    <template>
      <div class="vld-parent">
        <loading :active.sync="isLoading" :is-full-page="true"></loading>
      </div>
    </template>
    <v-snackbar
      :timeout="-1"
      :value="true"
      :class="toast_view"
      absolute
      centered
      top
      color="deep-purple accent-4"
      elevation="24"
    >
      <span id="toast_text"> Sorry, There's no strategy in your data!</span>
    </v-snackbar>
  </v-row>
</template>

<script>
import $ from "jquery";
// import Moment from "moment-timezone";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import EventAnalyzerService from "../services/EventService";
import "material-design-icons-iconfont/dist/material-design-icons.css";

export default {
  name: "EventAnalyzer",
  components: {
    Loading,
  },
  data() {
    return {
      isLoading: false,
      toast_view: "hidden",
      selectedCurrency: "",
      selectedEvent: "",
      selectedEventIndex: 0,
      selectedEventTime: "",
      selectedSymbol: "",
      currencyList: [],
      eventList: [],
      dateRangeText: "",
      tempEventList: [],
      eventTimeList: [],
      eventTimeNativeList: [],
      symbolList: [],
      headers_tbl_analyzer: [],
      items_tbl_analyzer: [],
      intervalList: [
        -120, -90, -60, -30, -15, -10, -5, -2, -1, 1, 2, 5, 10, 15, 30, 60, 90,
        120,
      ],
      selectedIntervals: [-60, -30, -15, 15, 30, 60],
      relevance: "All",
      eventSearch: "",
      menu: false,
      items_relevance: ["All", 1, 2, 3],
      dates: [],
      sel_dates: [],
      cur_year: "",
      cur_month: "",
      cur_date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),

      options_tbl_trades: {
        itemsPerPage: 15,
      }
    };
  },
  computed: {
    // dateRangeText() {
    //   if (this.sel_dates[0] > this.sel_dates[1]) {
    //     this.exchange_date();
    //   }
    //   return this.sel_dates.join(" ~ ");
    // },
  },
  methods: {
    saveDates() {
      if (this.sel_dates[0] > this.sel_dates[1]) {
        this.exchange_date();
      }
      this.dates[0] = this.sel_dates[0];
      this.dates[1] = this.sel_dates[1];
      this.dateRangeText = this.sel_dates.join(" ~ ");
      this.menu = false;
      this.getDates();
    },
    cancelDates() {
      this.sel_dates[0] = this.dates[0];
      this.sel_dates[1] = this.dates[1];
      this.dateRangeText = this.sel_dates.join(" ~ ");
      this.menu = false;
    },
    exchange_date() {
      let tmp_date = this.sel_dates[0];
      this.sel_dates[0] = this.sel_dates[1];
      this.sel_dates[1] = tmp_date;
    },
    getCurrencyList() {
      this.currencyList = [];
      this.isLoading = true;
      EventAnalyzerService.getCurrencyList()
        .then((response) => {
          if (response.data.success) {
            this.currencyList = response.data.currencyList;
            this.selectedCurrency = this.currencyList[0];
            this.getSymbols();
          }
        })
        .catch(() => {
          this.isLoading = false;
        });
    },
    searchEvent() {
      if (this.eventSearch == "") {
        this.eventList = this.tempEventList;
      }else {
        this.eventList = [];
        this.tempEventList.map((eve) => {
          if (eve.toLowerCase().includes(this.eventSearch.toLowerCase())) {
            this.eventList.push(eve);
          }
        });
      }
    },
    selEvent(index) {
      this.selectedEvent = this.eventList[index];
      this.getDates();
    },
    getDates() {
      if (this.selectedEvent) {
        this.isLoading = true;
        let params = {
          currency: this.selectedCurrency,
          event: this.selectedEvent,
          from: this.sel_dates[0],
          to: this.sel_dates[1]? this.sel_dates[1] : this.sel_dates[0],
        };
        console.log(params);
        EventAnalyzerService.getDate(params)
          .then((response) => {
            if (response.data.success) {
              this.eventTimeList = [];
              this.eventTimeNativeList = [];
              response.data.ecEvents.map((item) => {
                if (!this.eventTimeList.includes(item.Date.substr(0, 10) + " " + ("00" + item.Time.Hour).substr(-2) + ":" + ("00" + item.Time.Minute).substr(-2))) {
                  this.eventTimeList.push(
                    item.Date.substr(0, 10) +
                      " " +
                      ("00" + item.Time.Hour).substr(-2) +
                      ":" +
                      ("00" + item.Time.Minute).substr(-2)
                  );
                  this.eventTimeNativeList.push(
                    item.Date.substr(0, 10) +
                      "T" +
                      ("00" + item.Time.Hour).substr(-2) +
                      ":" +
                      ("00" + item.Time.Minute).substr(-2) +
                      ":00"
                  );
                }
                // this.eventTimeList.push(`${item.Date.substr(0, 10)}T${("00"+item.Time.Hour).substr(0,2)}:${("00"+item.Time.Minute).substr(0,2)}`)
                // this.eventTimeVisibleList.push(`${item.Date.substr(0, 10)} ${("00"+item.Time.Hour).substr(0,2)}:${("00"+item.Time.Minute).substr(0,2)}`)
              });
              this.items_tbl_analyzer = [];
              if (this.selectedSymbol &&
                  this.selectedIntervals.length &&
                  this.eventTimeList.length
                  ) {
                    this.changedIntervals();
                  } else this.isLoading = false;
            } else this.isLoading = false;
          })
          .catch(() => {
            this.isLoading = false;
          });
      }
    },

    getEvents() {
      this.eventList = [];
      this.isLoading = true;
      $("#eventListTitle").html(`Event List for "${this.selectedCurrency}"`);
      let params = {
        currency: this.selectedCurrency,
        relevance: this.relevance == "All" ? null : this.relevance,
        search: null,
      };
      EventAnalyzerService.getEvents(params)
        .then((response) => {
          if (response.data.success) {
            this.eventList = response.data.ecEvents;
            this.tempEventList = response.data.ecEvents;
          }
          this.isLoading = false;
        })
        .catch(() => {
          this.isLoading = false;
        });
    },

    changedIntervals() {
      this.items_tbl_analyzer = [];
      if (this.selectedIntervals.length) {
        this.selectedIntervals.sort(function (a, b) {
          return a - b;
        });
        this.headers_tbl_analyzer = [
          { text: "Date", value: "date", sortable: true },
        ];
        this.selectedIntervals.map((interval, index) => {
          this.headers_tbl_analyzer.push({
            text: interval,
            value: "Value" + index,
            sortable: true,
          });
        });
        this.getEventAnalyzer();
      }
    },

    getEventAnalyzer() {
      // let eventData = {};

      if (!this.eventTimeList.length) {
        return;
      }
      if (!this.selectedIntervals.length || !this.selectedSymbol) {
        return;
      }
      this.isLoading = true;
      let params = {
        Dates: this.eventTimeList,
        Minutes: this.selectedIntervals
      };
      EventAnalyzerService.getEventTableData(this.selectedSymbol, params)
        .then((response) => {
          for (const date in response.data) {
            if (Object.hasOwnProperty.call(response.data, date)) {
              const event_arr = response.data[date];
              let item = {'date' : date};
              event_arr.map((val, index) => {
                item["Value" + index] = (Math.round(val * 10) / 10).toFixed(1);
              });
              this.items_tbl_analyzer.push(item);
            }
          }
          this.isLoading = false;
        })
        .catch(() => {
          this.isLoading = false;
          alert("Something went wrong!");
        });
      // EventAnalyzerService.getPips(this.selectedSymbol)
      //   .then((response) => {
      //     let symbolPips = response.data.pips;
      //     let params = {
      //       symbolName: this.selectedSymbol,
      //       minMinute: Math.min(...this.selectedIntervals),
      //       maxMinute: Math.max(...this.selectedIntervals) + 1,
      //     };
      //     EventAnalyzerService.getEventData(params, this.eventTimeList)
      //       .then((response) => {
      //         if (response.data) {
      //           eventData = response.data;
      //           let baseval = {};
      //           let avgList = { date: "Average" };
      //           this.eventTimeNativeList.map((date, idx) => {
      //             if (date in eventData && eventData[date].length) {
      //               baseval[date] = eventData[date][0];
      //               if (date in eventData) {    
      //                 let prevDiff = Math.floor((new Date(eventData[date][0].openTime.substr(0,19)) - new Date(date.substr(0,19))) / 1000 / 60);
      //                 eventData[date].map((item) => {
      //                   let diff = Math.floor((new Date(item.openTime.substr(0,19)) - new Date(date.substr(0,19))) / 1000 / 60);
      //                   if ( Math.abs(diff) < Math.abs(prevDiff))
      //                     baseval[date] = { open: item.open, close: item.close };
      //                   prevDiff = diff;
                        
      //                 });
      //                 let analData = { date: this.eventTimeList[idx] };
      //                 this.selectedIntervals.map((interval, index) => {
      //                   if (!avgList["Value" + index]) avgList["Value" + index] = 0;
      //                   let maxval = 0;
      //                   if (interval < 0) {
      //                     eventData[date].map((item) => {
      //                        let timediff = Math.floor((new Date(item.openTime.substr(0,19)) - new Date(date.substr(0,19))) / 1000 / 60);
      //                        if(timediff < 0 && timediff > interval) {
      //                          let valDiff = Math.max(Math.abs(item.high - baseval[date].open), Math.abs(item.low - baseval[date].open));
      //                          if(valDiff > maxval) maxval = valDiff;
      //                        }
      //                     });
      //                   } else {
      //                     eventData[date].map((item) => {
      //                        let timediff = Math.floor((new Date(item.openTime.substr(0,19)) - new Date(date.substr(0,19))) / 1000 / 60);
      //                        if(timediff > 0 && timediff < interval) {
      //                          let valDiff = Math.max(Math.abs(item.high - baseval[date].close), Math.abs(item.low - baseval[date].close));
      //                          if(valDiff > maxval) maxval = valDiff;
      //                        }
      //                     });
      //                   }
      //                   analData["Value" + index] = (maxval * Math.pow(10, symbolPips)).toFixed(1);
      //                   avgList["Value" + index] += Number(analData["Value" + index]);
      //                 });
      //                 this.items_tbl_analyzer.push(analData);
      //               }
      //             }
      //           });
      //           // this.eventTimeNativeList.map((date) => {
      //           //   baseval[date] = eventData[date][0];
      //           //   interval_data[date] = {};
      //           //   let prevDiff = Math.floor((new Date(eventData[date][0].openTime.substr(0,19)) - new Date(date.substr(0,19))) / 1000 / 60);
      //           //   eventData[date].map((item) => {
      //           //     let diff = Math.floor((new Date(item.openTime.substr(0,19)) - new Date(date.substr(0,19))) / 1000 / 60);
      //           //     this.selectedIntervals.map((interval) => {
      //           //       if(diff == interval)
      //           //         interval_data[date][interval] = item;
      //           //     });
      //           //     if ( Math.abs(diff) < Math.abs(prevDiff))
      //           //       baseval[date] = { open: item.open, close: item.close };
      //           //     prevDiff = diff;
      //           //   });
      //           // });
      //           // let avgList = { date: "Average" };
      //           // this.eventTimeNativeList.map((date, dateIndex) => {
      //           //   let analData = { date: this.eventTimeList[dateIndex] };
      //           //   this.selectedIntervals.map((interval, index) => {
      //           //     if (!avgList["Value" + index]) avgList["Value" + index] = 0;
      //           //     if (interval < 0)
      //           //       analData["Value" + index] = interval_data[date][interval] ? (
      //           //         (baseval[date].close - interval_data[date][interval].open) *
      //           //         Math.pow(10, symbolPips)
      //           //       ).toFixed(1) : "";
      //           //     else
      //           //       analData["Value" + index] = interval_data[date][interval] ? (
      //           //         (interval_data[date][interval].close - baseval[date].open) *
      //           //         Math.pow(10, symbolPips)
      //           //       ).toFixed(1) : "";
      //           //     avgList["Value" + index] += analData["Value" + index] == "" ? 0 : Math.abs(Number(analData["Value" + index]));
      //           //   });
      //           //   this.items_tbl_analyzer.push(analData);
      //           // });
      //           for (const key in avgList) {
      //             if (avgList.hasOwnProperty.call(avgList, key)) {
      //               if (key != "date") {
      //                 avgList[key] = (
      //                   avgList[key] / this.eventTimeNativeList.length
      //                 ).toFixed(1);
      //               }
      //             }
      //           }
      //           this.items_tbl_analyzer.push(avgList);
      //         }
      //         this.isLoading = false;
      //       })
      //       .catch(() => {
      //         this.isLoading = false;
      //       });
      //   })
      //   .catch(() => {
      //     this.isLoading = false;
      //     alert("Something went wrong!");
      //   });
    },
    getSymbols() {
      this.symbolList = [];
      EventAnalyzerService.getSymbolList()
        .then((res) => {
          if (res.data) {
            this.symbolList = res.data.sort();
          }
          this.getEvents();
        })
        .catch(() => {
          this.isLoading = false;
        });
    },
  },
  async mounted() {
    $("#title").html("SUNA EventAnalyzer");
    $("#logo_title").html("");
    this.cur_year = new Date().getFullYear();
    this.cur_month = new Date().getMonth();
    this.dates = [
      Number(this.cur_month) == 0
        ? (Number(this.cur_year) - 1).toString() + "-12-01"
        : this.cur_year + "-" + ("0" + this.cur_month).substr(-2) + "-01",
      this.cur_date,
    ];
    this.sel_dates = this.dates;
    this.dateRangeText = this.sel_dates.join(" ~ ");
    this.toast_view = "hidden";
    this.getCurrencyList();
    this.items_tbl_analyzer = [];
    if (this.selectedSymbol &&
        this.selectedIntervals.length &&
        this.eventTimeList.length)
        this.changedIntervals();
    $(".v-main").removeClass("notfound");
    $(".v-main").removeClass("homePage");
    $(".v-main").removeClass("loginPage");
    $(".v-main").removeClass("guestPage");
    $(".v-main").removeClass("amdinPage");
    $(".v-application--wrap").removeClass("spreadPage");
    $(".v-application--wrap").removeClass("tradingUnits");
    $(".v-main").addClass("eventPage");
    $("#localtime").addClass("hidden");
  },
};
</script>

<style>
.eventPage.v-main {
  width: auto;
  min-width: 300px !important;
  text-align: center;
}
.eventPage .row.filterContainer {
  padding: 0;
  height: fit-content !important;
  margin: 0 auto !important;
}
.eventContainer .row.filterContainer .col:nth-child(2) {
  padding-left: 5px !important;
  padding-right: 5px !important;
}
.analyzerContainer .row.filterContainer {
  padding-bottom: 5px;
}
.eventContainer .row.filterContainer {
  padding-bottom: 5px;
  max-width: 95%;
  align-items: center;
}
.calendarContainer .v-toolbar,
.eventContainer .v-toolbar {
  max-height: 45px !important;
}
.calendarContainer .v-toolbar__content,
.eventContainer .v-toolbar__content {
  min-height: 100% !important;
}
.eventContainer {
  margin-bottom: 20px;
}
.calendarContainer {
  padding: 0 20px;
  margin-bottom: 20px;
}
.eventContainer .row.filterContainer .col,
.eventContainer {
  padding: 0 !important;
}
.v-list-item__content {
  text-align: left;
}
.calendarContainer .v-item-group,
.eventContainer .v-item-group {
  max-height: calc(100vh - 200px);
  overflow: auto;
}
.eventContainer .row.filterContainer .col #sel_relevance {
  display: none;
}
.eventContainer .row.filterContainer .col .v-input__control {
  max-height: 40px;
}
.analyzerContainer #tbl_analyzer {
  max-width: 95%;
  margin: 0 auto;
}
.analyzerContainer {
  padding: 0;
  margin-bottom: 10px;
}
@media (max-width: 1366px) {
  #sel_eventdate_range .v-input input {
    font-size: 14.5px;
  }
}
.eventPage .v-sheet.v-toolbar {
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
}
.eventPage #sel_eventdate_range {
  margin-bottom: 12px;
}
.eventPage .v-list {
  padding: 0 !important;
  border-radius: 5px;
}
.eventPage #sel_eventdate_range .v-icon.mdi-calendar {
  top: 0.2rem !important;
}
@media (max-width: 600px) {
  .calendarContainer {
    padding: 0 !important;
    min-width: 95%;
    margin: 0 auto;
    display: flow-root;
  }
  .analyzerContainer {
    padding: 0;
    margin: 0 auto;
    display: flow-root;
  }
}
@media (max-width: 360px) {
  .v-item-group {
    width: calc(100vw - 30px) !important;
    overflow: auto;
  }
}
@media (min-width: 960px) {
  .calendarContainer.col-md-3 {
    flex: 0 0 25%;
    max-width: 24%;
  }
  .eventContainer.col-md-4 {
    flex: 0 0 33%;
    max-width: 30%;
  }
}
@media (min-width: 1264px) {
  .col-lg-6 {
    flex: 0 0 45%;
    max-width: 45%;
    align-items: center;
  }
}
.symbol_interval > div:first-child {
  max-width: 30%;
}
.symbol_interval > div:last-child {
  max-width: 68%;
}
.symbol_interval {
  display: flex;
  max-width: 95%;
  justify-content: space-between;
}
</style>
