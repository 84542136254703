import axios from "axios";

const headers = {
  "Content-Type": "application/json",
  "Access-Control-Allow-Origin": "analysis.suna.ai",
};

export default {
  local: axios.create({
    baseURL: "/api/",
    // baseURL: "https://console.suna.ai/api/",
    // baseURL: "http://10.10.13.181:5000/api/",
    headers,
  }),
  demo: axios.create({
    baseURL: "https://demo.suna.ai/api/",
    headers,
  }),
};
