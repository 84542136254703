<template>
  <v-col class="row">
    <img class="background" src="../assets/background.png" alt="background" />
    <v-col class="main mt-2 pa-5 col-12" xl="3" lg="3" sm="6" md="6">
      <div id="item_container">
        <template>
          <v-card class="mx-auto" width="auto" min-width="150" max-width="650">
            <v-img src="../assets/demoweb.png"></v-img>

            <v-card-title>Main Board</v-card-title>

            <v-card-subtitle>
              <v-card-actions class="text-center">
                <v-btn
                  class="ma-auto pa-5 sunademo"
                  color="error"
                  dark
                  large
                  rounded
                >
                  <a :href="demoLink"> Visit Now </a>
                </v-btn>
              </v-card-actions>
            </v-card-subtitle>
          </v-card>
        </template>
      </div>
    </v-col>
    <v-col class="main mt-2 pa-5 col-12" xl="3" lg="3" sm="6" md="6">
      <div id="item_container">
        <template>
          <v-card class="mx-auto" width="auto" min-width="150" max-width="650">
            <v-img src="../assets/suna-event-analyzer.png"></v-img>

            <v-card-title>Event Analyzer</v-card-title>

            <v-card-subtitle>
              <v-card-actions class="text-center">
                <v-btn
                  class="ma-auto pa-5"
                  color="error"
                  dark
                  large
                  rounded
                  @click="visit_event"
                >
                  Visit Now
                </v-btn>
              </v-card-actions>
            </v-card-subtitle>
          </v-card>
        </template>
      </div>
    </v-col>
    <v-col class="main mt-2 pa-5 col-12" xl="3" lg="3" sm="6" md="6">
      <div id="item_container">
        <template>
          <v-card class="mx-auto" width="auto" min-width="150" max-width="650">
            <v-img src="../assets/suna-spread-info.png"></v-img>

            <v-card-title>Spread Infomation</v-card-title>

            <v-card-subtitle>
              <v-card-actions class="text-center">
                <v-btn
                  class="ma-auto pa-5"
                  color="error"
                  dark
                  large
                  rounded
                  @click="visit_spread"
                >
                  Visit Now
                </v-btn>
              </v-card-actions>
            </v-card-subtitle>
          </v-card>
        </template>
      </div>
    </v-col>
    <v-col class="main mt-2 pa-5 col-12" xl="3" lg="3" sm="6" md="6">
      <div id="item_container">
        <template>
          <v-card class="mx-auto" width="auto" min-width="150" max-width="650">
            <v-img src="../assets/suna-trading-unit.png"></v-img>

            <v-card-title>Account Simulation</v-card-title>

            <v-card-subtitle>
              <v-card-actions class="text-center">
                <v-btn
                  class="ma-auto pa-5"
                  color="error"
                  dark
                  large
                  rounded
                  @click="visit_trading"
                >
                  Visit Now
                </v-btn>
              </v-card-actions>
            </v-card-subtitle>
          </v-card>
        </template>
      </div>
    </v-col>
    <h1>Suna Analysis</h1>
    <template>
      <div class="vld-parent">
        <loading :active.sync="isLoading" :is-full-page="true"></loading>
      </div>
    </template>
  </v-col>
</template>

<script>
import $ from "jquery";
import UserService from "../services/UserService";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";

export default {
  name: "home",
  components: {
    Loading,
  },
  data: () => ({
    isLoading: false,
    demoLink: "https://console.suna.ai/",
  }),
  methods: {
    visit_event() {
      this.$router.push({ path: "/eventAnalyzer" });
    },
    visit_trading() {
      this.$router.push({ path: "/accountSimulation" });
    },
    visit_spread() {
      this.$router.push({ path: "/spreadInfo" });
    },
  },
  async beforeMount() {
    const loggedIn = localStorage.getItem("user");
    if (!loggedIn) {
      let demoToken = this.$route.params.demoToken;
      if (demoToken) {
        this.isLoading = true;
        let user = { loginType: "demoToken", demoToken };
        UserService.login(user)
          .then((res) => {
            if (res.data.success) {
              localStorage.setItem("demoToken", demoToken);
              localStorage.setItem("user", res.data.token);
              localStorage.setItem("visitor", "admin");
              localStorage.setItem("permission", res.data.user.IsAdmin);
            }
            this.isLoading = false;
            this.$router.push({ path: "/home" });
          })
          .catch((e) => {
            console.log("getDemoUser Error", e);
            this.isLoading = false;
          });
      } else {
        this.$router.push({ path: "/login" });
      }
    }
  },
  async mounted() {
    $("#title").html("SUNA Analysis");
    $("#logo_title").html("");
    $(".v-main").removeClass("guestPage");
    $(".v-main").removeClass("loginPage");
    $(".v-main").removeClass("adminPage");
    $(".v-main").removeClass("eventPage");
    $(".v-main").removeClass("notFound");
    setTimeout(() => {
      if (localStorage.getItem("permission") == "true") {
        $(".permissionRoute").removeClass("hidden");
      } else {
        $(".permissionRoute").addClass("hidden");
      }
      $(`.permissionRoute.home`).addClass("path");
      if (!$(".permissionRoute").length) {
        location.reload();
      }
    }, 200);
    $(".v-application--wrap").removeClass("spreadPage");
    $(".v-application--wrap").removeClass("tradingUnits");
    $("#localtime").removeClass("hidden");
    $("#localtime").addClass("hidden");
    $(".v-main").addClass("homePage");
  },
};
</script>
<style>
.sunademo a {
  color: white !important;
  text-decoration: none !important;
}
.homePage.v-main .v-card__text {
  padding: 16px !important;
  text-align: left;
}
.homePage.v-main .v-card__title {
  color: white !important;
  font-weight: bold;
}
.homePage.v-main .v-card__title {
  padding: 10px 20px !important;
  font-size: 1.5rem !important;
  margin-top: 10px;
  margin-bottom: 20px;
  letter-spacing: 0.05rem !important;
  background: #109;
}
.homePage.v-main .v-responsive__content {
  width: 100% !important;
}
.homePage.v-main {
  width: auto;
  text-align: center;
  min-width: 250px;
}
#item_container:hover {
  box-shadow: 0 0 10px 0px #0005;
  transform: scale(1.01);
}
#item_container {
  box-shadow: 0 0 3px 0px #0003;
  width: 100%;
  border-radius: 15px;
  z-index: 1;
}
.homePage .row h1 {
  text-align: center;
  font-weight: bolder;
  text-transform: uppercase;
  font-size: 80px;
  position: absolute;
  color: chocolate;
  opacity: 0.5;
  text-shadow: 0 0 3px #ff000077, 0 0 5px #0000ff88;
  bottom: 20px;
}
.homePage .row {
  justify-content: space-around;
}

.homePage .main {
  display: flex;
  justify-content: center;
  margin: auto !important;
}
.homePage .v-sheet.v-card {
  border-radius: 15px;
  background: #fffe;
}
.homePage .v-btn.error {
  background-color: #ff0000 !important;
  border-color: #ff0000 !important;
  font-weight: bold;
  margin: 20px auto !important;
}
</style>
