import http from "../http-common";

class TradingUnitDataService {
  getTradingUnits(id) {
    let auth = localStorage.getItem("user");
    return http.local.get("/tradingUnits", {
      headers: { Authorization: `Bearer ${auth}` },
      params: { id }
    });
  }
  getIDs() {
    let auth = localStorage.getItem("user");
    return http.local.get("/tradingUnits/names", {
      headers: { Authorization: `Bearer ${auth}` }
    });
  }
  getDemoTradingUnits(id) {
    let auth = localStorage.getItem("demoToken");
    return http.demo.get("/simulationResult?id=" + id, {
      headers: { Authorization: `Bearer ${auth}` }
    });
  }
  getPublicTradingUnits(name) {
    return http.demo.get("/simulationResult/publicResults?resultName=" + name);
  }
  getDemoIDs() {
    let auth = localStorage.getItem("demoToken");
    return http.demo.get("/simulationResult/names", {
      headers: { Authorization: `Bearer ${auth}` }
    });
  }
}

export default new TradingUnitDataService();