import $ from "jquery";
import Vue from "vue";
import Router from "vue-router";
import LoginComponent from "../pages/login";
// import LandingComponent from "../pages/landing";
import HomeComponent from "../pages/home";
import TradingComponent from "../pages/accountSimulaion";
import EventComponent from "../pages/eventAnalyzer";
import SpreadComponent from "../pages/spreadInfo";
import NotFoundComponent from "../pages/404";
Vue.use(Router);

export const router = new Router({
  mode: "history",
  routes: [
    {
      path: "/",
      redirect: {
        name: "login",
      },
      component: LoginComponent,
    },
    {
      path: "/home/:demoToken",
      name: "demoHome",
      component: HomeComponent,
    },
    {
      path: "/login",
      name: "login",
      component: LoginComponent,
    },
    {
      path: "/guest/login",
      name: "guestLogin",
      component: LoginComponent,
    },
    {
      path: "/accountSimulation",
      name: "accountSimulation",
      component: TradingComponent,
    },
    {
      path: "/eventAnalyzer",
      name: "eventAnalyzer",
      component: EventComponent,
    },
    {
      path: "/spreadInfo",
      name: "spreadInfo",
      component: SpreadComponent,
    },
    {
      path: "/guest/:strategy_name",
      name: "guestSimulation",
      component: TradingComponent,
    },
    {
      path: "/public/:strategy_name",
      name: "publicSimulation",
      component: TradingComponent,
    },
    {
      path: "/404",
      name: "notFound",
      component: NotFoundComponent,
    },
    {
      path: "/home",
      name: "home",
      component: HomeComponent,
    },
    { path: "*", redirect: "/404" },
  ],
});

router.beforeEach((to, from, next) => {
  // redirect to login page if not logged in and trying to access a restricted page
  const publicPages = ["login", "guestLogin", "notFound", "demoHome", "publicSimulation"];
  const authRequired = !publicPages.includes(to.name);
  const loggedIn = localStorage.getItem("user");
  const userPermission = localStorage.getItem("permission");
  const visitor = localStorage.getItem("visitor");
  if (loggedIn && (to.name == "login" || to.name == "guestLogin")) {
    if (!userPermission || visitor == "guest")
      return next("/guest/:strategy_name");
    else return next("/home");
  }
  if (authRequired) {
    if (!loggedIn) {
      localStorage.removeItem("user");
      localStorage.removeItem("permission");
      localStorage.setItem("prevPath", to.path);
      if (to.path.substring(0, 6) == "/guest") {
        return next("/guest/login");
      } else {
        return next("/login");
      }
    } else {
      if (
        (!userPermission || visitor == "guest") &&
        to.name != "guestSimulation"
      ) {
        return next("/guest/:strategy_name");
      }
    }
  } else {
    if (to.name != "demoHome") {
      localStorage.removeItem("demoToken");
      localStorage.removeItem("user");
    }
  }
  if (to.name == "login") localStorage.setItem("visitor", "admin");
  else if (to.name == "guestLogin") localStorage.setItem("visitor", "guest");
  setTimeout(() => {
    $(".permissionRoute").removeClass("path");
    $(`.permissionRoute.${to.name}`).addClass("path");
  }, 200);
  next();
});
