<template>
  <v-row align="center" class="list">
    <div class="row spreadInfoFilter">
      <v-col lg="4" md="4" sm="9" class="pa-0">
        <v-autocomplete
          v-model="selectedSymbol"
          :items="symbolNames"
          label="Symbol"
          outlined
          dense
          @change="onSelectSymbolChange"
        ></v-autocomplete>
      </v-col>
      <v-col lg="2" md="2" sm="3" class="pa-0 pl-3">
        <v-btn
          class="mx-2"
          fab
          dark
          small
          color="green"
          @click="retrieveSymbols"
        >
          <v-icon dark> mdi-cached </v-icon>
        </v-btn>
      </v-col>
      <v-col lg="6" md="6" sm="12" class="switch_bar">
        <v-btn color="red" id="switch_item" @click="switch_view_item">
          Switch View Item
        </v-btn>
        <v-radio-group
          v-model="switch_item"
          @change="updateChart"
          mandatory
          row
          id="switch_item"
        >
          <v-radio
            label="Average Spread"
            name="switch_item"
            value="Spread"
            class="ml-5"
          ></v-radio>
          <v-radio
            label="Max Spread"
            class="ml-5"
            name="switch_item"
            value="MaxSpread"
          ></v-radio>
        </v-radio-group>
      </v-col>
    </div>

    <!-- ---------------------------------------------- -->
    <div class="row chart_container">
      <!-- ---------------------------------------------- -->

      <div class="col-12 chartBox_spread" id="chart_sun">
        <div class="chartHeader">
          <p class="chartTitle">Sunday</p>
        </div>
        <div class="chartContent">
          <apexchart
            type="area"
            height="250"
            :options="chartOptions"
            :series="series_sun"
          ></apexchart>
        </div>
      </div>

      <!-- ---------------------------------------------- -->
      <div class="col-12 chartBox_spread" id="chart_mon">
        <div class="chartHeader">
          <p class="chartTitle">Monday</p>
        </div>
        <div class="chartContent">
          <apexchart
            type="area"
            height="250"
            :options="chartOptions"
            :series="series_mon"
          ></apexchart>
        </div>
      </div>

      <!-- ---------------------------------------------- -->

      <div class="col-12 chartBox_spread" id="chart_tue">
        <div class="chartHeader">
          <p class="chartTitle">Tuesday</p>
        </div>
        <div class="chartContent">
          <apexchart
            type="area"
            height="250"
            :options="chartOptions"
            :series="series_tue"
          ></apexchart>
        </div>
      </div>

      <!-- ---------------------------------------------- -->

      <div class="col-12 chartBox_spread" id="chart_wed">
        <div class="chartHeader">
          <p class="chartTitle">Wednesday</p>
        </div>
        <div class="chartContent">
          <apexchart
            type="area"
            height="250"
            :options="chartOptions"
            :series="series_wed"
          ></apexchart>
        </div>
      </div>

      <!-- ---------------------------------------------- -->

      <div class="col-12 chartBox_spread" id="chart_thu">
        <div class="chartHeader">
          <p class="chartTitle">Thursday</p>
        </div>
        <div class="chartContent">
          <apexchart
            type="area"
            height="250"
            :options="chartOptions"
            :series="series_thu"
          ></apexchart>
        </div>
      </div>

      <!-- ---------------------------------------------- -->

      <div class="col-12 chartBox_spread" id="chart_fri">
        <div class="chartHeader">
          <p class="chartTitle">Friday</p>
        </div>
        <div class="chartContent">
          <apexchart
            type="area"
            height="250"
            :options="chartOptions"
            :series="series_fri"
          ></apexchart>
        </div>
      </div>

      <!-- ---------------------------------------------- -->

      <div class="col-12 chartBox_spread" id="chart_sat">
        <div class="chartHeader">
          <p class="chartTitle">Saturday</p>
        </div>
        <div class="chartContent">
          <apexchart
            type="area"
            height="250"
            :options="chartOptions"
            :series="series_sat"
          ></apexchart>
        </div>
      </div>

      <!-- ---------------------------------------------- -->
    </div>
    <!-- ---------------------------------------------- -->

    <template>
      <div class="vld-parent">
        <loading :active.sync="isLoading" :is-full-page="true"></loading>
      </div>
    </template>
  </v-row>
</template>

<script>
import VueApexCharts from "vue-apexcharts";
import $ from "jquery";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import SpreadInfoService from "../services/SpreadInfoService";
import "material-design-icons-iconfont/dist/material-design-icons.css";

export default {
  name: "SpreadInfo",
  components: {
    apexchart: VueApexCharts,
    Loading,
  },
  data() {
    return {
      isLoading: false,
      selectedSymbol: "",
      pipPosition: 0,
      switch_item: "Spread",
      symbolNames: [],
      series: [
        {
          name: "IC Market",
          type: "area",
          color: "blue",
          data: [],
        },
        {
          name: "RoboForex",
          type: "line",
          color: "#e5e928",
          data: [],
        },
        {
          name: "Fondex",
          type: "line",
          color: "#ee4488",
          data: [],
        },
      ],
      series_mon: [],
      series_tue: [],
      series_wed: [],
      series_thu: [],
      series_fri: [],
      series_sat: [],
      series_sun: [],
      chartOptions: {
        stroke: {
          width: [2, 5, 3],
          curve: "smooth",
          colors: ["blue", "#e5e928", "#ee4488"],
        },
        dataLabels: {
          enabled: false,
        },
        xaxis: {
          type: "category",
          categories: [
            "00.00",
            "00.15",
            "00.30",
            "00.45",
            "01.00",
            "01.15",
            "01.30",
            "01.45",
            "02.00",
            "02.15",
            "02.30",
            "02.45",
            "03.00",
            "03.15",
            "03.30",
            "03.45",
            "04.00",
            "04.15",
            "04.30",
            "04.45",
            "05.00",
            "05.15",
            "05.30",
            "05.45",
            "06.00",
            "06.15",
            "06.30",
            "06.45",
            "07.00",
            "07.15",
            "07.30",
            "07.45",
            "08.00",
            "08.15",
            "08.30",
            "08.45",
            "09.00",
            "09.15",
            "09.30",
            "09.45",
            "10.00",
            "10.15",
            "10.30",
            "10.45",
            "11.00",
            "11.15",
            "11.30",
            "11.45",
            "12.00",
            "12.15",
            "12.30",
            "12.45",
            "13.00",
            "13.15",
            "13.30",
            "13.45",
            "14.00",
            "14.15",
            "14.30",
            "14.45",
            "15.00",
            "15.15",
            "15.30",
            "15.45",
            "16.00",
            "16.15",
            "16.30",
            "16.45",
            "17.00",
            "17.15",
            "17.30",
            "17.45",
            "18.00",
            "18.15",
            "18.30",
            "18.45",
            "19.00",
            "19.15",
            "19.30",
            "19.45",
            "20.00",
            "20.15",
            "20.30",
            "20.45",
            "21.00",
            "21.15",
            "21.30",
            "21.45",
            "22.00",
            "22.15",
            "22.30",
            "22.45",
            "23.00",
            "23.15",
            "23.30",
            "23.45",
            // "23.59",
          ],
          labels: {
            rotate: -5,
            offsetX: 7,
            hideOverlappingLabels: true,
          },
        },
        yaxis: {
          opposite: true,
        },
        fill: {
          type: "gradient",
          opacity: [1, 1, 1],
          gradient: {
            opacityFrom: 0.75,
            opacityTo: 0.1,
            colorStops: [
              [
                {
                  offset: 0.2,
                  color: "#1fe",
                  opacity: 0.8,
                },
                {
                  offset: 100,
                  color: "#11ffee11",
                  opacity: 0.2,
                },
              ],
              [
                {
                  offset: 100,
                  color: "#e5e928",
                  opacity: 1,
                },
                {
                  offset: 100,
                  color: "#e5e928",
                  opacity: 1,
                },
              ],
              [
                {
                  offset: 100,
                  color: "#ee4488",
                  opacity: 1,
                },
                {
                  offset: 100,
                  color: "#ee4488",
                  opacity: 1,
                },
              ],
            ],
          },
        },
        grid: {
          show: true,
          borderColor: "#90A4AE",
          strokeDashArray: 0.2,
          position: "back",
          xaxis: {
            lines: {
              show: true,
            },
          },
          yaxis: {
            lines: {
              show: true,
            },
          },
        },
        tooltip: {
          x: {
            format: "HH:mm",
          },
        },

        legend: {
          position: "top",
          horizontalAlign: "left",
        },
      },
      chart_icMarket: [],
      chart_roboForex: [],
      chart_fondex: [],
    };
  },

  methods: {
    updateChart() {
      this.series_mon = [
        { ...this.series[0], data: this.chart_icMarket[1][this.switch_item] },
        { ...this.series[1], data: this.chart_roboForex[1][this.switch_item] },
        { ...this.series[2], data: this.chart_fondex[1][this.switch_item] },
      ];
      this.series_tue = [
        { ...this.series[0], data: this.chart_icMarket[2][this.switch_item] },
        { ...this.series[1], data: this.chart_roboForex[2][this.switch_item] },
        { ...this.series[2], data: this.chart_fondex[2][this.switch_item] },
      ];
      this.series_wed = [
        { ...this.series[0], data: this.chart_icMarket[3][this.switch_item] },
        { ...this.series[1], data: this.chart_roboForex[3][this.switch_item] },
        { ...this.series[2], data: this.chart_fondex[3][this.switch_item] },
      ];
      this.series_thu = [
        { ...this.series[0], data: this.chart_icMarket[4][this.switch_item] },
        { ...this.series[1], data: this.chart_roboForex[4][this.switch_item] },
        { ...this.series[2], data: this.chart_fondex[4][this.switch_item] },
      ];
      this.series_fri = [
        { ...this.series[0], data: this.chart_icMarket[5][this.switch_item] },
        { ...this.series[1], data: this.chart_roboForex[5][this.switch_item] },
        { ...this.series[2], data: this.chart_fondex[5][this.switch_item] },
      ];
      this.series_sat = [
        { ...this.series[0], data: this.chart_icMarket[6][this.switch_item] },
        { ...this.series[1], data: this.chart_roboForex[6][this.switch_item] },
        { ...this.series[2], data: this.chart_fondex[6][this.switch_item] },
      ];
      this.series_sun = [
        { ...this.series[0], data: this.chart_icMarket[0][this.switch_item] },
        { ...this.series[1], data: this.chart_roboForex[0][this.switch_item] },
        { ...this.series[2], data: this.chart_fondex[0][this.switch_item] },
      ];
    },
    retrieveSymbols() {
      this.symbolNames = [];
      this.isLoading = true;
      SpreadInfoService.getSymbolNames()
        .then((response) => {
          if (response.data.numSymbols > 0) {
            this.symbolNames = response.data.symbolNames.sort();
            this.selectedSymbol = this.symbolNames[0];
            this.onSelectSymbolChange();
          }
        })
        .catch((e) => {
          console.log(e);
          this.isLoading = false;
        });
    },
    switch_view_item() {
      if (this.switch_item == "Spread") this.switch_item = "MaxSpread";
      else this.switch_item = "Spread";
      this.updateChart();
    },
    onSelectSymbolChange() {
      this.isLoading = true;
      this.chart_icMarket = [];
      this.chart_roboForex = [];
      this.chart_fondex = [];
      for (let i = 0; i < 7; i++) {
        this.chart_icMarket.push([]);
        this.chart_icMarket[i] = {};
        this.chart_icMarket[i].Spread = [];
        this.chart_icMarket[i].MaxSpread = [];
        this.chart_roboForex.push([]);
        this.chart_roboForex[i] = {};
        this.chart_roboForex[i].Spread = [];
        this.chart_roboForex[i].MaxSpread = [];
        this.chart_fondex.push([]);
        this.chart_fondex[i] = {};
        this.chart_fondex[i].Spread = [];
        this.chart_fondex[i].MaxSpread = [];
      }
      SpreadInfoService.getSpreadInfo(this.selectedSymbol)
        .then((response) => {
          if (response.data.spreadInfo) {
            this.pipPosition = response.data.spreadInfo.pipPosition;

            response.data.spreadInfo.infoData.map((item) => {
              switch (item.Broker) {
                case "IC Markets":
                  item.SpreadValues.map((subItem) => {
                    this.chart_icMarket[subItem.DayOfWeek].Spread.push(
                      Number(
                        (
                          Number(subItem.Spread) *
                          Math.pow(10, this.pipPosition)
                        ).toFixed(2)
                      )
                    );
                    this.chart_icMarket[subItem.DayOfWeek].MaxSpread.push(
                      Number(
                        (
                          Number(subItem.MaxSpread) *
                          Math.pow(10, this.pipPosition)
                        ).toFixed(2)
                      )
                    );
                  });
                  break;
                case "roboforex":
                  item.SpreadValues.map((subItem) => {
                    this.chart_roboForex[subItem.DayOfWeek].Spread.push(
                      Number(
                        (
                          Number(subItem.Spread) *
                          Math.pow(10, this.pipPosition)
                        ).toFixed(2)
                      )
                    );
                    this.chart_roboForex[subItem.DayOfWeek].MaxSpread.push(
                      Number(
                        (
                          Number(subItem.MaxSpread) *
                          Math.pow(10, this.pipPosition)
                        ).toFixed(2)
                      )
                    );
                  });
                  break;
                case "fondex":
                  item.SpreadValues.map((subItem) => {
                    this.chart_fondex[subItem.DayOfWeek].Spread.push(
                      Number(
                        (
                          Number(subItem.Spread) *
                          Math.pow(10, this.pipPosition)
                        ).toFixed(2)
                      )
                    );
                    this.chart_fondex[subItem.DayOfWeek].MaxSpread.push(
                      Number(
                        (
                          Number(subItem.MaxSpread) *
                          Math.pow(10, this.pipPosition)
                        ).toFixed(2)
                      )
                    );
                  });
                  break;
                default:
                  break;
              }
            });
          }
          this.isLoading = false;
          this.updateChart();
        })
        .catch((e) => {
          console.log(e);
          this.isLoading = false;
        });
    },
  },
  async mounted() {
    $("#title").html("SUNA Spread Infomation");
    $(".v-messages").remove();
    this.switch_item = "Spread";
    this.retrieveSymbols();
    $("#logo_title").html("");
    $(".v-main").removeClass("notfound");
    $(".v-main").removeClass("homePage");
    $(".v-main").removeClass("loginPage");
    $(".v-main").removeClass("eventPage");
    $(".v-main").removeClass("guestPage");
    $(".v-application--wrap").removeClass("tradingUnits");
    $("#localtime").removeClass("hidden");
    $(".v-main").addClass("adminPage");
    $(".v-application--wrap").addClass("spreadPage");
    $(".apexcharts-inner.apexcharts-graphical").attr(
      "transform",
      "translate(14, 80)"
    );
    setInterval(function () {
      const currentTime = new Date().toUTCString();
      $("#localtime").html(currentTime + " (UTC)");
    }, 1000);
  },
};
</script>
