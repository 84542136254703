import http from "../http-common";

class EventAnalyzerService {
  getDemoAuthToken(email, password) {
    return http.demo.post("/users/login", {
      email,
      password,
    });
  }
  getCurrencyList() {
    let auth = localStorage.getItem("user");
    return http.local.get("/eventData/getCurrencyList", {
      headers: { Authorization: `Bearer ${auth}` },
    });
  }
  getSymbolList() {
    let auth = localStorage.getItem("demoToken");
    let headers = { Authorization: `Bearer ${auth}` };
    return http.demo.get("/barDatas/symbols", {
      headers,
    });
  }
  getEventData(params, timeData) {
    let auth = localStorage.getItem("demoToken");
    let headers = { Authorization: `Bearer ${auth}` };
    return http.demo.post("/barDatas/barElementsForAllDates", timeData, {
      headers,
      params,
    });
  }
  getEventTableData(symbol, params) {
    let auth = localStorage.getItem("demoToken");
    let headers = { Authorization: `Bearer ${auth}` };
    return http.demo.post("/barDatas/movements?symbolName=" + symbol, params, {
      headers,
    });
  }
  getPips(symbol) {
    let auth = localStorage.getItem("user");
    return http.local.get("/eventData/getPips", {
      headers: { Authorization: `Bearer ${auth}` },
      params: { symbol },
    });
  }
  getEvents(params) {
    let auth = localStorage.getItem("user");
    return http.local.get("/eventData/getEvents", {
      headers: { Authorization: `Bearer ${auth}` },
      params,
    });
  }
  getDate(params) {
    let auth = localStorage.getItem("user");
    return http.local.get("/eventData/getDate", {
      headers: { Authorization: `Bearer ${auth}` },
      params,
    });
  }
}

export default new EventAnalyzerService();
