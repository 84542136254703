import http from "../http-common";

class SpreadInfoService {
  getSpreadInfo(symbol) {
    let auth = localStorage.getItem("user");
    return http.local.post(
      "/spreadInfo",
      {
        symbol: symbol,
      },
      {
        headers: { Authorization: `Bearer ${auth}` },
      }
    );
  }
  getSymbolNames() {
    let auth = localStorage.getItem("user");
    return http.local.get("/spreadInfo/symbolNames", {
      headers: { Authorization: `Bearer ${auth}` },
    });
  }
}

export default new SpreadInfoService();
