<template>
  <v-col class="main" lg="4" sm="8" xs="12" md="6">
    <img class="background" src="../assets/background.png" alt="background" />
    <div id="login_container">
      <h1 id="login_lbl">Log In</h1>
      <v-form ref="form" v-model="valid" lazy-validation>
        <v-col id="email_container">
          <v-text-field
            v-model="email"
            :rules="
              validAuth
                ? [rules.required, rules.email]
                : [rules.required, rules.email, rules.emailmatch]
            "
            label="E-mail"
            @keyup.enter="login"
            @keyup="inputchange"
            hint="Please enter your Email Address!"
            class="input-group--focused"
          ></v-text-field>
        </v-col>
        <v-col id="pw_container">
          <v-text-field
            :append-icon="show_pw ? 'mdi-eye' : 'mdi-eye-off'"
            :rules="
              validAuth ? [rules.required] : [rules.required, rules.emailmatch]
            "
            :type="show_pw ? 'text' : 'password'"
            name="password"
            label="Password"
            v-model="password"
            hint="Please Enter your Passwrord"
            @keyup.enter="login"
            @keyup="inputchange"
            @click:append="show_pw = !show_pw"
            class="input-group--focused"
          ></v-text-field>
        </v-col>
        <v-btn
          :disabled="!valid"
          color="success"
          class="mr-4"
          @click="login"
          id="login_btn"
        >
          Login
        </v-btn>
      </v-form>
      <template>
        <div class="vld-parent">
          <loading :active.sync="isLoading" :is-full-page="true"></loading>
        </div>
      </template>
    </div>
    <v-snackbar
      :timeout="-1"
      :value="true"
      :class="toast_view"
      absolute
      centered
      top
      color="deep-purple accent-4"
      elevation="24"
    >
      Sorry, User Email or password is not correct ! Please try again...
    </v-snackbar>
  </v-col>
</template>

<script>
import Loading from "vue-loading-overlay";
import $ from "jquery";
import "vue-loading-overlay/dist/vue-loading.css";
import UserService from "../services/UserService";
import EventAnalyzerService from "../services/EventService";

export default {
  name: "Login",
  components: {
    Loading,
  },
  data: () => ({
    visitor: "",
    toast_view: "hidden",
    authenticated: false,
    valid: false,
    validAuth: false,
    password: "",
    show_pw: false,
    email: "",
    isLoading: false,
    rules: {
      required: (value) => !!value || "Required.",
      counter: (value) => value.length <= 20 || "Max 20 characters",
      email: (value) => {
        const pattern =
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return pattern.test(value) || "Invalid e-mail.";
      },
      emailmatch: () => `The email and password you entered don't match`,
    },
  }),

  methods: {
    inputchange() {
      this.validAuth = true;
    },
    get_demo_token(response) {
      this.isLoading = true;
      EventAnalyzerService.getDemoAuthToken(this.email, this.password)
        .then((res) => {
          if (res.data.success) {
            localStorage.setItem("demoToken", res.data.auth_token);
            let prevPath = localStorage.getItem("prevPath");
            if (prevPath) {
              if (prevPath != "/") this.$router.push({ path: prevPath });
              else this.$router.push("/home");
            } else {
              if (
                localStorage.getItem("visitor") == "admin" &&
                response.data.user.IsAdmin
              )
                this.$router.push({ path: "/home" });
              else {
                localStorage.setItem("visitor", "guest");
                localStorage.setItem("prevPath", "login");
                this.$router.push({ path: "/guest/XAU" });
              }
            }
            this.isLoading = false;
          }
        })
        .catch((e) => {
          console.log("getDemoToken Error", e);
          this.isLoading = false;
        });
    },
    login() {
      if (!this.valid) {
        this.validAuth = false;
        this.rules.emailmatch = false;
        return;
      }
      this.isLoading = true;
      this.validAuth = true;
      let user = { email: this.email, password: this.password };
      UserService.login(user)
        .then((response) => {
          if (response) {
            if (response.data.success) {
              localStorage.setItem("user", response.data.token);
              localStorage.setItem("permission", response.data.user.IsAdmin);
              this.get_demo_token(response);
            } else {
              this.toast_view = "";
              this.validAuth = false;
              this.rules.emailmatch = false;
              setTimeout(() => {
                this.toast_view = "hidden";
              }, 5000);
              this.isLoading = false;
            }
          }
        })
        .catch(() => {
          this.isLoading = false;
          this.validAuth = false;
        });
    },
  },
  async mounted() {
    $("#title").html("SUNA");
    this.valid = false;
    this.validAuth = false;
    this.toast_view = "hidden";
    this.visitor = localStorage.getItem("visitor");
    if (this.visitor == "admin") $("#email_container").removeClass("hidden");
    else {
      $("#email_container").addClass("hidden");
      this.email = "user@suna.ai";
    }
    setTimeout(() => {
      $(".permissionRoute").addClass("hidden");
    }, 200);
    $("#logo_title").html("");
    $(".v-main").removeClass("notfound");
    $(".v-main").removeClass("homePage");
    $(".v-main").removeClass("guestPage");
    $(".v-main").removeClass("adminPage");
    $(".v-main").removeClass("eventPage");
    $(".v-application--wrap").removeClass("spreadPage");
    $(".v-application--wrap").removeClass("tradingUnits");
    $("#localtime").removeClass("hidden");
    $("#localtime").addClass("hidden");
    $(".v-main").addClass("loginPage");
    this.authenticated = false;
  },
};
</script>
<style>
.loginPage.v-main {
  width: auto;
  min-width: 400px !important;
  text-align: center;
  overflow: hidden;
}
#login_lbl {
  margin: 25px 0;
}
#login_btn {
  margin: 25px 16px !important;
}
#pw_container,
#email_container {
  align-items: center;
  margin: auto;
}
#login_container {
  padding: 20px;
  box-shadow: 0 0 5px 0px #0002;
  background: #ffffff33;
  border: 2px solid rgba(255, 165, 0, 0.5);
  border-radius: 15px;
  z-index: 1;
}
#login_container .v-input__control .v-text-field__details {
  display: flex !important;
}
#login_container .v-application--is-ltr .v-messages {
  display: block !important;
  padding-top: 5px;
}
.btn {
  display: inline-block;
  padding: 6px 12px;
  margin-bottom: 0;
  font-size: 14px;
  font-weight: normal;
  line-height: 1.428571429;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  cursor: pointer;
  background-image: none;
  border: 1px solid transparent;
  border-radius: 4px;
}

.btn:focus {
  outline: thin dotted #333;
  outline: 5px auto -webkit-focus-ring-color;
  outline-offset: -2px;
}
*,
*:before,
*:after {
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
</style>
